import React, { Component } from 'react';
import { COLORS } from './../utils/constants';
import styled from 'styled-components';
import { easeInOutQuart } from './../utils/helpers';
import LogoAnimated from './LogoAnimated';
import Sky from './Sky';

const Wrapper = styled.div`
  width: ${props => props.componentSize && props.componentSize.width ? props.componentSize.width + 'px' : '100vw'};
  height: ${props => props.moveProgress && props.componentSize && props.componentSize.height ? props.componentSize.height * (1 - props.moveProgress) + 'px' : '100vh'};
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.backgroundColor};
`;

const Content = styled.div`
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
`;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowSize: {width: window.innerWidth, height: window.innerHeight},
      moveProgress: 0,            // 0 – 100
      logoProgress: 0,            // 0 – 100
      easing: 0.02,
      animationProgress: 0,
      mouse: {x: 0, y: 0},
      theme: {
        foregroundColor: COLORS.white,
        backgroundColor: COLORS.darkBlue,
      }
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.onWindowResize);
    window.addEventListener('mouseup', this.onMouseUp);
    requestAnimationFrame(this.tick);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
    window.removeEventListener('mouseup', this.onMouseUp);
  }

  onWindowResize = () => {
    const w = window,
          d = document,
          documentElement = d.documentElement,
          body = d.getElementsByTagName('body')[0],
          width = w.innerWidth || documentElement.clientWidth || body.clientWidth,
          height = w.innerHeight|| documentElement.clientHeight|| body.clientHeight;

    this.setState({windowSize: {width: width, height: height}});
  }

  tick = () => {
    const { moveProgress, animationProgress, logoProgress } = this.state;

    if (animationProgress < 400) {
      if (animationProgress > 80 && logoProgress <= 1) {
        const target = animationProgress > 300 ? 0 : 1;
        const delta = target - logoProgress;
        const progress = logoProgress + delta * this.state.easing;

        this.setState({ logoProgress: progress })
      }

      if (animationProgress > 300 && moveProgress <= 100) {
        this.setState({ moveProgress: moveProgress + 1 })
      }

      requestAnimationFrame(this.tick);
      this.setState({ animationProgress: animationProgress + 1 })
    }

    // requestAnimationFrame(this.tick);
    // this.setState({ animationProgress: animationProgress + 1 })
  }

  render() {
    const { theme, windowSize, moveProgress, logoProgress, animationProgress } = this.state;
    const smoothMoveProgress = easeInOutQuart(moveProgress/100);

    // TODO: shooting star

    return (
      <Wrapper componentSize={windowSize} moveProgress={smoothMoveProgress} theme={theme}>
        <Content componentSize={windowSize} moveProgress={smoothMoveProgress}>
          <Sky
            amountOfStars={300}
            moveProgress={smoothMoveProgress}
            animationProgress={animationProgress}
            theme={theme}
            componentSize={windowSize}
            />
        </Content>

        <LogoAnimated
          fill={false}
          logoProgress={logoProgress}
          theme={theme}
        />
      </Wrapper>
    );
  }
}

export default Header;