import React, { Component } from 'react';

export default class Lines extends Component {
  constructor() {
    super();
    this.paths = []
    this.state = {
      length: {}
    }
  }

  componentDidMount() {
    const length = this.paths.map( (p, i) => p.getTotalLength() )
    this.setState({ length })
  }
  
  render() {
    const { animationProgress, theme } = this.props;
    const { length } = this.state;

    return <g>
      { this.props.logo.path.map( (shape, key) => {
        return <path
          key={key}
          d={shape}
          ref={(ref) => this.paths[key] = ref}
          strokeDasharray={ length[key] }
          strokeDashoffset={ length[key] && (1 - animationProgress) * length[key] }
          stroke={theme.foregroundColor}
          strokeWidth="5"
          fill="none"
        />
      })}
    </g>
  }
}