import React, { Component } from 'react';
import star from './../assets/star.png';

export default class LogoStarsCanvas extends Component {
  state = {
    img: null,
  }

  componentDidMount() {
    const img = new Image();
    img.src = 'https://static.mercuriusassurance.com/header-intro/star.png';
    img.onload = () => {
      this.setState({img})
    }

    this.drawCanvas();
  }

  componentDidUpdate() {
    this.drawCanvas();
  }

  drawCanvas = () => {
    const { img } = this.state;
    const canvas = this.refs.canvas;
    const ctx = canvas.getContext("2d");

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    
    if (img) {
      this.props.logo.paths.forEach( shape => (
        shape.forEach( (point, key) => {
          if (point.values.length > 0) {
            const x = (point.values[point.values.length-2] + 20) / this.props.ratio.w - point.size/2;
            const y = (point.values[point.values.length-1] + 20) / this.props.ratio.h - point.size/2;
            ctx.drawImage(img, x, y, point.size, point.size);
          }
        })
      ))
    }
  }

  render() {
    return (
      <canvas
        ref="canvas"
        width={this.props.width}
        height={this.props.height}
      />
    );
  }
}