const svgWidth = 160;
const ratio = 0.65625;
const svgHeight = svgWidth * ratio;


export const Logo = {
    dimensions: {
        width: svgWidth,
        height: svgHeight,
        viewBox: "-20 -20 1040 681",
        ratio: {
            w: 1040.0 / svgWidth,
            h: 681.0 / svgHeight,
        },
    },
    path: [
        'M425,432.828568 L424.675431,433.626175 L342.268989,637.5 L340.47614,637.5 L130.712841,176.635021 C125.334294,164.978903 118.162899,149.736287 113.680778,137.183544 L109.459486,129.343831 C100.551279,115.382117 93.9594419,107.594937 86.7880475,98.6286919 L13.28125,1.79324894 L13.28125,0 L227.526671,0 L414.879361,408.860759 L425,432.828568',
        'M134.199298,501.622596 L225.78125,622.402511 L225.78125,624.21875 L0,624.21875 L0,622.402511 L91.5819525,501.622596 C101.556225,488.000801 124.225025,488.000801 134.199298,501.622596',
        'M558.091686,91.3899938 L594.944163,0 L783.59375,0 L783.59375,1.77587127 L725.682713,66.5951725 C717.785756,74.5865931 716.908313,76.3624644 716.908313,88.7935631 L716.908313,545.192479 C716.908313,556.735642 717.785756,559.399449 725.682713,566.502934 L783.59375,622.442879 L783.59375,624.21875 L491.40625,624.21875 L491.40625,622.442879 L550.194726,566.502934 C557.214246,559.399449 558.091686,556.735642 558.091686,545.192479 L558.091686,91.3899938',
    ],
    circle: {
        x: 917.1875,
        y: 78.125,
        radiusMin: 10,
        radius: 68.125,
    }
};
