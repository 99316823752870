import React, { Component } from 'react';
import styled from 'styled-components';
import star from './../assets/star.png';

const Canvas = styled.canvas`
  /* position: fixed; */
`;

export default class Sky extends Component {
  state = {
    img: null,
    stars: [...Array(this.props.amountOfStars)].map( s => 
      {
        const offset = Math.random() * parseFloat(this.props.componentSize && this.props.componentSize.height ? this.props.componentSize.height : window.innerHeight)/2;

        const shouldFlicker = Math.random() > 0.6 ? true : false;
        
        return {
          size: Math.random() * 10 + 5.01,
          brightness: Math.random() * 0.7 + 0.3,
          shouldFlicker,
          flicherFrequency: Math.random() * 40 + 20,
          position: {
            x: Math.random(),
            y: Math.random(),
            offset,
          }
        }
      }
    )
  }

  componentDidMount() {
    const img = new Image();
    img.src = 'https://static.mercuriusassurance.com/header-intro/star.png';
    img.onload = () => {
      this.setState({img})
    }

    this.drawCanvas();
  }

  componentDidUpdate() {
    this.drawCanvas();
  }

  drawCanvas = () => {
    const { componentSize, moveProgress, animationProgress } = this.props;
    const { img } = this.state;
    const canvas = this.refs.canvas;
    const ctx = canvas.getContext("2d");

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    
    if (img) {
      this.state.stars.forEach(s => {
        const x = s.position.x * componentSize.width;
        const y = (s.position.y * (componentSize.height + s.position.offset)) - (s.position.offset * moveProgress);
        const blink = s.shouldFlicker ? Math.cos(animationProgress/s.flicherFrequency)/2.5 + 0.6 : 1;
        ctx.globalAlpha = s.brightness * blink;
        ctx.drawImage(img, x, y, s.size, s.size);
      });
    }
  }

  render() {
    const { componentSize } = this.props;

    return (
      <Canvas
        ref="canvas"
        stars={this.state.stars}
        width={componentSize.width}
        height={componentSize.height}
      />
    );
  }
}