import React, { Component } from 'react';
import styled from 'styled-components';
import { Logo } from '../assets/logo';
import { svgPathToCommands } from '../utils/svg-parse';
import Lines from './LogoLines';
import LogoStars from './LogoStarsCanvas';
// import { Shape } from './LogoShape';

const Div = styled.div`
  width: ${props => props.size.width + 'px'};
  height: ${props => props.size.height + 'px'};
  position: relative;
  svg {
    position: absolute;
  }
  canvas {
    position: absolute;
  }
`;

const Svg = styled.svg`
  position: static;
  -webkit-transition: all 0.8s;
  transition: all 0.8s;
`;

export default class AnimatedLogo extends Component {
  constructor(props) {
    super(props);
    this.svg = React.createRef();
    this.state = {
      logo: {
        path: Logo.path,
        paths: Logo.path.map( shape => svgPathToCommands(shape).map( (s, i) => {
          const segmentLength = (1 / (svgPathToCommands(shape).length));
          const segmentStart = i / (svgPathToCommands(shape).length);
          return ({
            ...s,
            speed: Math.random() * 4 + 1,
            size: Math.random() * 9 + 3,
            range: {
              start: segmentStart,
              stop: segmentStart + segmentLength
            },
          }
        )}))
      },
    }
  }
    
  render() {
    const { logo } = this.state;
    const { theme, logoProgress } = this.props;

    return(
      <Div size={{ width: Logo.dimensions.width, height:Logo.dimensions.height }}>
        <Svg ref={this.svg} width={Logo.dimensions.width} height={Logo.dimensions.height} viewBox={Logo.dimensions.viewBox}>
          {/* { this.props.fill && <Shape logo={logo} animationProgress={logoProgress} theme={theme} />} */}

          <Lines logo={logo} animationProgress={logoProgress} theme={theme} />

          <circle cx={Logo.circle.x} cy={Logo.circle.y} r={logoProgress * Logo.circle.radius + Logo.circle.radiusMin} fill={theme.foregroundColor}></circle>
        </Svg>

        <LogoStars
          logo={logo}
          theme={theme}
          width={Logo.dimensions.width}
          height={Logo.dimensions.height}
          ratio={Logo.dimensions.ratio}
        />
      </Div>
    )
  }
}
