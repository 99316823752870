// from https://gist.github.com/shamansir/0ba30dc262d54d04cd7f79e03b281505

const markerRegEx = /[MmLlSsQqLlHhVvCcSsQqTtAaZz]/g;
const digitRegEx = /-?[0-9]*\.?\d+/g;

export const svgPathToCommands = (str) => {
  let results = []; 
  let match; while ((match = markerRegEx.exec(str)) !== null) { results.push(match); };
  return results
    .map(function(match) {
        return { marker: str[match.index], 
                  index: match.index };
    })
    .reduceRight(function(all, cur) {
        const chunk = str.substring(cur.index, all.length ? all[all.length - 1].index : str.length);
        return all.concat([
            { marker: cur.marker, 
              index: cur.index, 
              chunk: (chunk.length > 0) ? chunk.substr(1, chunk.length - 1) : chunk }
        ]);
    }, [])
    .reverse()
    .map(function(command) {
        const values = command.chunk.match(digitRegEx);
        return { marker: command.marker, values: values ? values.map(parseFloat) : []};
    });
};

export const commandsToSvgPath = (commands) => {
  return commands.map(function(command) {
      return command.marker + ' ' + command.values.join(',');
  }).join(' ').trim();
};
